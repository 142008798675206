import React, { useState, useMemo } from 'react';

import { useRouter } from 'next/router';

import {
  TrophyOutlined,
  BugOutlined,
  MoneyCollectOutlined,
} from '@ant-design/icons';
import { Layout, Menu, type MenuProps } from 'antd';

import {
  Template,
  Text,
  The,
  Element,
  Profile,
  Texture,
  Settings,
  Postcard,
  Logout,
  Image,
  Tshirt,
  Background,
  Scene,
  StarPair,
  PromptTemplate,
  Backup,
} from 'components/Icons';
import Flex from 'components/ui/Flex';
import { Account, Role } from 'types/user';
import { config as globalConfig } from 'utils/config';

import styles from './SideMenu.module.css';

type MenuItem = Required<MenuProps>['items'][number] & {
  access?: Role[];
  children?: Array<{ label: string; key: string; access?: Role[] }>;
};

const menuItems: MenuItem[] = [
  {
    label: 'Templates',
    key: 'templates',
    icon: <Template />,
    access: [Role.ContentManager, Role.Reviewer],
    children: [
      {
        label: 'Categories',
        access: [Role.ContentManager],
        key: '/templates',
      },
      {
        label: 'Master Layouts',
        access: [Role.ContentManager],
        key: '/templates/masterLayouts',
      },
      {
        label: 'Review',
        access: [Role.ContentManager, Role.Reviewer],
        key: '/templates/review',
      },
      {
        label: 'Dismissed',
        access: [Role.ContentManager],
        key: '/templates/dismissed',
      },
    ],
  },
  {
    label: 'AI Images',
    key: 'aiArt',
    access: [Role.ContentManager],
    icon: <StarPair />,
    children: [
      {
        label: 'Library',
        key: '/aiArt',
      },
      {
        label: 'Review',
        key: '/aiArt/review',
      },
      {
        label: 'Dismissed',
        key: '/aiArt/dismissed',
      },
    ],
  },
  {
    label: 'Fonts',
    key: '/fonts',
    access: [Role.ContentManager],
    icon: <Text />,
  },
  {
    label: 'Text Layouts',
    key: '/layouts',
    access: [Role.ContentManager],
    icon: <The />,
  },
  {
    label: 'Mockups',
    key: '/mockups',
    access: [Role.ContentManager, Role.MockupManager],
    icon: <Tshirt />,
  },
  {
    label: 'Elements',
    key: 'elements',
    access: [Role.ContentManager, Role.ContentCreator],
    icon: <Element />,
    children: [
      {
        label: 'Search',
        key: '/elements/search',
      },
      {
        label: 'Illustrations',
        key: '/elements/illustrations',
      },
      {
        label: 'Ornaments',
        key: '/elements/ornaments',
      },
      {
        label: 'Abstract',
        key: '/elements/abstract',
      },
      {
        label: 'Shapes',
        key: '/elements/shapes',
      },
      {
        label: 'Uploads',
        key: '/elements/upload',
      },
    ],
  },
  {
    label: 'Textures',
    key: '/elements/overlay',
    access: [Role.ContentManager, Role.ContentCreator],
    icon: <Texture />,
  },
  {
    label: 'Backgrounds',
    key: '/elements/background',
    access: [Role.ContentManager, Role.ContentCreator],
    icon: <Background />,
  },
  {
    label: 'Prompt Templates',
    icon: <PromptTemplate />,
    access: [Role.AIManager],
    key: 'prompttemplates',
    children: [
      {
        label: 'Templates',
        key: '/prompttemplates',
      },
      {
        label: 'Variables',
        key: '/prompttemplates/variables',
      },
    ],
  },
  {
    label: 'Prompt Styles',
    key: '/promptstyles',
    access: [Role.AIManager],
    icon: <Image />,
  },
  {
    label: 'Prompt Scenes',
    key: '/promptscenes',
    access: [Role.AIManager],
    icon: <Scene />,
  },
  {
    label: 'CORS',
    key: '/origins',
    access: [Role.Admin],
    icon: <Settings />,
  },
  {
    label: 'Prices',
    key: 'prices',
    access: [Role.Admin],
    icon: <MoneyCollectOutlined />,
    children: [
      {
        label: 'List',
        key: '/prices',
      },
    ],
  },
  {
    label: 'Feature Groups',
    key: 'featuregroups',
    access: [Role.Admin],
    icon: <Profile />,
    children: [
      {
        label: 'List',
        key: '/featuregroups',
      },
      {
        label: 'Create',
        key: '/featuregroups/new',
      },
    ],
  },
  {
    label: 'Invites',
    key: 'invites',
    access: [Role.Support],
    icon: <Postcard />,
    children: [
      {
        label: 'Invite List',
        key: '/invites',
      },
      {
        label: 'Promo Codes',
        key: '/promocodes',
      },
    ],
  },
  {
    label: 'Challenges',
    key: '/challenges',
    access: [Role.Admin, Role.ChallengeManager],
    icon: <TrophyOutlined />,
  },
  {
    label: 'Users',
    key: '/users',
    access: [Role.Support],
    icon: <Profile />,
  },
  {
    label: 'Design Backups',
    key: '/designbackups',
    access: [Role.Support],
    icon: <Backup />,
  },
  {
    label: 'POD',
    key: 'products',
    access: [Role.Admin],
    children: [
      {
        label: 'Catalog',
        key: '/products',
      },
    ],
    icon: <Tshirt />,
  },
  ...(globalConfig.ENVIRONMENT === 'production'
    ? []
    : [
        {
          label: 'Testing',
          key: '/testing',
          access: [Role.Admin],
          icon: <BugOutlined />,
        },
      ]),
  {
    label: 'Logout',
    key: '/logout',
    icon: <Logout />,
  },
];

const hasAccess = (accessRoles: Role[], account: Account) => {
  return accessRoles.some((subItemRole) => account[subItemRole]);
};

const getSelectedKeys = (path: string) => {
  let selectedKey: string;
  menuItems.forEach((item) => {
    if (!item.children && path.startsWith(item.key.toString())) {
      selectedKey = item.key.toString();
    }

    if (item.children) {
      item.children.forEach((child) => {
        if (path.startsWith(child.key.toString())) {
          selectedKey = child.key;
        }
      });
    }
  });
  return selectedKey ? [selectedKey] : [];
};

interface SideMenuProps {
  account?: Account;
}

export const SideMenu: React.FC<SideMenuProps> = ({ account }) => {
  const router = useRouter();
  const [openKeys, setOpenKeys] = useState<string[]>([
    getOpenKeyOnRefresh(router.asPath),
  ]);

  const allowedMenuItems = useMemo(
    () =>
      menuItems.reduce((acc, item) => {
        // items with no `access` should be rendered for everyone (e.g. Logout)
        // or if user is admin we skip the logic because they have access to everything
        if (!item.access || account.admin) {
          return [...acc, item];
        }

        const userHasAccess = hasAccess(item.access, account);

        // remove sub items which the user has no access to
        if (userHasAccess && item.children) {
          const newChildren = item.children.filter((subItem) =>
            // sub-items with no `access` prop should be rendered for users with access to this section
            subItem.access ? hasAccess(subItem.access, account) : true
          );
          item.children = newChildren;
        }

        return userHasAccess ? [...acc, item] : acc;
      }, []),
    [account]
  );

  return (
    <Layout.Sider collapsible style={{ userSelect: 'none' }} width={220}>
      <Flex
        align="center"
        className={styles.logo}
        justify="center"
        onClick={() => router.push('/')}
      >
        CMS
      </Flex>

      <Menu
        items={allowedMenuItems}
        mode="inline"
        openKeys={openKeys}
        selectedKeys={getSelectedKeys(router.asPath)}
        theme="dark"
        onClick={({ key, keyPath }) => {
          const openKey = keyPath.length > 1 ? [keyPath[1]] : undefined;
          setOpenKeys(openKey);
          router.push(key);
        }}
        onOpenChange={setOpenKeys}
      />
    </Layout.Sider>
  );
};

// TODO: refactor the routing so we have a route structure where links don't share same routes
// Example: `/elements/overlay` is not under `/elements`, but shares same route and this causes issues in the Menu
const pathWithDuplicatedPath = ['/elements/overlay', '/elements/background'];

function getOpenKeyOnRefresh(path: string) {
  const isPathDuplicated = pathWithDuplicatedPath.includes(path);
  return isPathDuplicated ? undefined : path.slice(1).split('/')[0];
}
