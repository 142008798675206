import classnames from 'classnames';

import styles from './Flex.module.css';

interface FlexProps extends React.HTMLAttributes<HTMLElement> {
  align?: 'start' | 'center' | 'end' | 'stretch';
  children: React.ReactNode;
  gap?: 'default' | 'small' | 'middle' | 'large';
  justify?:
    | 'start'
    | 'center'
    | 'space-between'
    | 'space-around'
    | 'space-evenly';
  wrap?: 'nowrap' | 'wrap' | 'wrap-reverse';
  vertical?: boolean;
}

const Flex = ({
  align = 'stretch',
  justify = 'start',
  gap = 'default',
  children,
  className = '',
  wrap = 'nowrap',
  vertical = false,
  ...otherProps
}: FlexProps) => {
  return (
    <div
      className={classnames(
        styles.flex,
        styles[vertical ? 'column' : 'row'],
        styles[`align-${align}`],
        styles[`justify-${justify}`],
        styles[`gap-${gap}`],
        styles[`wrap-${wrap}`],
        className
      )}
      {...otherProps}
    >
      {children}
    </div>
  );
};

export default Flex;
