export interface User {
  id: number;
  name: string;
  slug: string;
  profileImage: string;
}

export type Account = {
  email: string;
  name: string;
  slug: string;
  profileImage: string;
} & AccountRole;

export type AccountRole = {
  admin?: boolean;
  contentManager?: boolean;
  support?: boolean;
  reviewer?: boolean;
  contentCreator?: boolean;
  mockupManager?: boolean;
  aiManager?: boolean;
  challengeManager?: boolean;
};

export type UserSearchFilter = {
  roles?: UserRole[];
};

export enum Role {
  Admin = 'admin',
  ContentManager = 'contentManager',
  Support = 'support',
  Reviewer = 'reviewer',
  ContentCreator = 'contentCreator',
  Creator = 'creator',
  RevenueShare = 'revenueShare',
  MockupManager = 'mockupManager',
  AIManager = 'aiManager',
  ChallengeManager = 'challengeManager',
}

// Enum with the User Roles from database enum, to filter users by roles
export enum UserRole {
  CREATOR = 'CREATOR',
  CONTENT_MANAGER = 'CONTENT_MANAGER',
  CONTENT_CREATOR = 'CONTENT_CREATOR',
  ADMIN = 'ADMIN',
  SUPPORT = 'SUPPORT',
  REVENUE_SHARE = 'REVENUE_SHARE',
  REVIEWER = 'REVIEWER',
  TAXONOMY_EDITOR = 'TAXONOMY_EDITOR',
  MOCKUP_MANAGER = 'MOCKUP_MANAGER',
  AI_MANAGER = 'AI_MANAGER',
  CHALLENGE_MANAGER = 'CHALLENGE_MANAGER',
}

export type UserWithToken = {
  token: string;
  id: string;
  email: string;
  googleId: string | null;
  onboardingState: string | null;
  passwordDigest: string | null;
  passwordUpdatedAt: Date;
  confirmed: boolean;
  facebookId: string | null;
  slug: string;
  name: string | null;
  profileImage: string | null;
  isTeam: boolean;
  description: string | null;
  location: string | null;
  website: string | null;
  instagram: string | null;
  followerCount: number;
  followingCount: number;
  challengeStats: string | null;
  noindex: boolean;
  createdAt: string | Date;
  invitesLimit: number;
  featureGroups: string[];
  intercomHMAC: string;
  marketingOptIn: boolean;
  allowCommentEmails: boolean;
  allowShareViewEmails: boolean;
  plan: string | null;
  utilizedUploadSpace: number;
  uploadSpaceLimitReached: boolean;
  designsLimit: number;
  designsCount: number;
  designsLimitReached: boolean;
  shippingAddress: {
    name: string;
    line1: string;
    line2: string | null;
    postalCode: string;
    city: string;
    state: string;
    country: string;
  };
  verificationCreditsGranted: boolean;
  signupCampaign: string | null;
  teams: string[];
  generatedContentPublicMode: boolean;
  accessToken: string;
  roles?: string[];
  cmsId?: number | null;
  deletedAt?: Date | null;
  stripeId?: string | null;
  impactClickId?: string | null;
  adClickId?: string | null;
  ip?: string | null;
  fraudster?: boolean;
  extraProjects?: number;
  signupMedium?: string | null;
  signupSource?: string | null;
  teamId?: string | null;
  signupUrl?: string | null;
} & AccountRole;
