import React, { ReactNode } from 'react';

import { Layout as AntdLayout, theme } from 'antd';

import { Account } from 'types/user';

import { Header } from './Header';
import { SideMenu } from './SideMenu';

interface LayoutProps {
  children: ReactNode;
  account?: Account;
}

export const Layout: React.FC<LayoutProps> = ({ account, children }) => {
  const {
    token: { colorBgContainer, borderRadiusLG },
  } = theme.useToken();

  return (
    <AntdLayout hasSider style={{ minHeight: '100vh' }}>
      {account && <SideMenu account={account} />}
      <AntdLayout>
        <Header account={account} backgroundColor={colorBgContainer} />

        <AntdLayout.Content style={{ margin: '1rem' }}>
          <div
            style={{
              padding: '1rem',
              background: colorBgContainer,
              borderRadius: borderRadiusLG,
            }}
          >
            {children}
          </div>
        </AntdLayout.Content>
      </AntdLayout>
    </AntdLayout>
  );
};
